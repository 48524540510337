<template>
  <CCard class="m-0">
  <CCardBody>
    <CDataTable
      :items="circularGeofencesFiltered"
      :fields="fields"
      :items-per-page="6"
      hover
      pagination
      :cleaner="false"
      clickable-rows
      @row-clicked="setRowToActive"
      
      :striped="true"
      :table-filter="false"
      
      :tableFilterValue="searchValue"
      sorter
      :sorter-value="sorterValue"
      style="font-size: 14px"
    >
    <template #tags="{ item }">
      <td>
        <span v-for="(tag, index) in getTagObjects(item)" :key="tag.getUId()">
          <CBadge color="info" class="m-1 p-2 tagBadge" v-if="index < 3" shape="pill"> 
            <span> {{tag.getName()}} </span>
          </CBadge>
        </span>
        <span v-if="getTagObjects(item).length > 3">
          ...
        </span>
      </td>
    </template>
    <template #name="{ item }">
      <td>
        <b> {{ item.getName() }} </b>
      </td>
    </template>
    <template #branch="{ item }">
      <td>
        {{ item.getBranch() }}
      </td>
    </template>
    <template #show="{ item }">
      <td>
        <CButton
          color="info"
          variant="ghost"
          @click="detailsClick(item)"
        >
        Details
        </CButton>
      </td>
    </template>
    </CDataTable>
  </CCardBody>
  </CCard>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: "GeofenceTableCard",
  props: {
    fields: {
      type: Array,
      default () {
        return [
          "name",
          "branch",
          "tags",
          "show",
        ]
      }
    },
    activeGeofenceUID: {
      default () {
        return null;
      }
    },
    filteredGeoUIDs: {
      type: Array,
    },
    searchValue: {
      type: String,
    }, 
  },
  data() {
    return {
      sorterValue: { column: null, asc: false },
      
    }
  },
  methods: {
    setRowToActive(row) { //h_ms_01
      this.$emit('setActiveCircular', row.uid);
    },
    getTagObjects(geo) {
      return geo.getCategories().map(tag_uid => {
        return this.geofenceCategories.find(tag => {
          return tag.getUId() == tag_uid;
        })
      })
    },
    detailsClick (item) {
      this.$emit("detailsClick", item.getUId());
    }
  },
  computed: {
    ...mapGetters({
      circularGeofences: 'circularGeofences',
      geofenceCategories: "geofenceCategories",
    }),
       
    circularGeofencesFiltered () {
      return this.circularGeofences.filter(geo => {
        return this.filteredGeoUIDs.includes(geo.getUId());
      })
    },

  },
};
</script> 