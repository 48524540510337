<template>
  <CCardBody>
    <CDataTable
      :items="CTableData"
      :fields="fields"
      :items-per-page="20"
      hover
      pagination
      clickableRows	
      cleaner
      @row-clicked="setRowToFocus"
        >
    <template #lat="{ item }">
      <td>
        <CInput v-if="activeId == item.id"
          v-model="activeLat"
          :isValid="!$v.activeLat.$invalid"
          invalidFeedback="has to be a number between -90 and 90, with length under 25"
          @blur="showV"
        />
        <strong v-else>
          {{ item.lat }}
        </strong> 
      </td>
    </template>
    <template #lng="{ item }">
      <td>
        <CInput v-if="activeId == item.id"
          v-model="activeLng"
          :isValid="!$v.activeLng.$invalid"
          invalidFeedback="enter a number between -180 and 180, with length under 25"
          @blur="showV"
        />
        <strong v-else>
          {{ item.lng }}
        </strong>
      </td>
    </template>
    <template #editing="{ item }">
      <CButton v-show="!hasActiveId"
        color="primary"
        shape="square"
        size="lg"
        @click="editToggleOn(item.id)"
      > 
      edit
      </CButton>
      <CButton v-show="activeId == item.id && validated"
        color="success"
        shape="square"
        size="lg"
        @click="saveEdit(item.id)"
      > 
      save
      </CButton>
      <CButton v-show="activeId == item.id"
        color="warning"
        shape="square"
        size="lg"
        @click="editToggleOff(false)"
      > 
      cancel
      </CButton>
      <CButton v-show="!hasActiveId"
        color="success"
        shape="square"
        size="lg"
        @click="addCoordinates(item.id)"
      > 
      add
      </CButton>
    </template>
    </CDataTable>
    <CButton v-if="polygon && uploadOption"
      color="success"
      shape="square"
      size="lg"
      @click="uploadPolygonToStore()">
      upload edit
    </CButton>
  </CCardBody>
</template>

<script>

import { validationMixin } from "vuelidate";
import {
  required,
  between,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters } from 'vuex'
import {Polygon} from '@/lib/geofencing_lib'
import { geoListViewMode } from '@/lib/geofencing_lib';


export default {
  name: "CoordinateTable",
  props: {
    fields: {
      type: Array,
      default () {
        return [
          'id',
          {key: 'lat', label: 'Lat'},  
          {key: 'lng', label: 'Lng'}, 
          {key: 'editing', label: ""}, 
        ]
      }
    },
    activePolygonId: {
      type: String,
      default () {
        return null;
      }
    },
    editingMode: {
      type: String,
      default () {
        return geoListViewMode.SELECT;
      }
    },
    groupId: {
      type: String,
      default () {
        return null;
      }
    }
  },
  mixins: [validationMixin],
  validations: {
    // Custom Validator Functions for Form Input
    activeLat: {
      required,
      betweenValue: between(-90, 90),
      maxLengthValue: maxLength(25),
    },
    activeLng: {
      required,
      betweenValue: between(-180, 180),
      maxLengthValue: maxLength(25),
    }
  },
  data() {
    return {
      pointsData: [],
      CTableData: [],
      activeId: null,
      activeLat: null,
      activeLng: null,
      addInProgress: null, // store the id of the latlng being added
      polygonName: null,
      unsavedChange: false,
    }
  },
  methods: {
    setRowToFocus (row) {
      this.$emit('setMarkerAtPoint', {
        lat: row.lat, 
        lng: row.lng
      })
    },
    showV() {
      console.log("this.$v", this.$v);
    },

    editToggleOn (id) {
      this.activeId = id;
      let point = this.getPointById(id);
      this.activeLat = point.lat;
      this.activeLng = point.lng;
      this.$emit("isEditingAPoint", true);
    },

    saveEdit(id) {
      let point = this.getPointById(id);
      point.lat = this.activeLat;
      point.lng = this.activeLng;
      this.updateCTableData(id, this.activeLat, this.activeLng);
      this.editToggleOff(true);
      this.hasUnsavedChange(true);
    },

    hasUnsavedChange (bool) {
      this.unsavedChange = bool;
      if (bool) {
        this.$emit("newEdit", true);
      } else {
        this.$emit("newEdit", false);
      }
    },

    getPointById (id) {
      let result = this.pointsData.filter((point) => {
        return point.id == id;
      })
      if (result.length) {
        return result[0];
      }
      return null;
    },

    getRowById (id) {
      let result = this.CTableData.filter((row) => {
        return row.id == id;
      })
      if (result.length) {
        return result[0];
      }
      return null;
    },

    // removing the temporary entry from pointsData and CTableData
    cancelAdd() {
      let point = this.pointsData.findIndex(point => point.id == this.addInProgress);
      this.pointsData.splice(point, 1);

      let row = this.CTableData.findIndex(row => row.id == this.addInProgress);
      this.CTableData.splice(row, 1);

      this.addInProgress = null;
    },

    editToggleOff(onSave) {
      this.activeId = null;
      this.activeLat = null;
      this.activeLng = null;
      if (this.addInProgress && !onSave) {
        this.cancelAdd();
      }
      this.$emit("isEditingAPoint", false);
    },

    initializeCTableData() {
      this.CTableData = [];
      this.pointsData.forEach(point => {
        this.CTableData.push({
          id: point.id,
          lat: point.lat,
          lng: point.lng,
        })
      })
    },

    updateCTableData(id, lat, lng) {
      let row = this.getRowById(id);
      row.lat = lat;
      row.lng = lng;
    },

    // id indicates the position after which the new entry should be inserted
    insertCTableData(id, newId, lat, lng) {
      let idx = this.CTableData.findIndex(row => row.id == id); 
      this.CTableData.splice(idx + 1, 0, {
        id: newId,
        lat,
        lng,
      })  
    },

    initializePointsData() {
      this.pointsData = [];
      if (!this.polygon.points) {
        return ;
      }
      this.activeId = null;
      this.polygon.points.forEach(point => {
        this.pointsData.push({
          id: point.id,
          lat: point.lat,
          lng: point.lng,
        })
      })
      this.initializeCTableData();
    },

    addCoordinates (id) {
      let idx = this.pointsData.findIndex(row => row.id == id);
      let newId = this.generateId();

      this.pointsData.splice(idx+1, 0, {
        id: newId,
        lat: null,
        lng: null,
      })

      this.insertCTableData(id, newId, null, null);
      this.editToggleOn(newId);
      this.addInProgress = newId;
    },

    //h_0005_a2: implement generateId
    generateId() {
      return Math.random().toString();
    },
    
    uploadPolygonToStore () {
      // h_todo_0006_a1: might need to redo the mapping from pointsData to Store if either side changes.
      if (this.editingMode == 'DRAWING') {
        this.$store.commit("modifyATempPolygon", {
          poly_id: this.polygon.poly_id, // h_todo_0006_02
          points: this.pointsData,
        })
      } else {
        this.$store.commit("modifyAPolygon", new Polygon(this.polygon.poly_id, this.pointsData, this.groupId));
      }
      this.hasUnsavedChange(false);
    }, 

    clearData () {
      this.pointsData = [];
      this.CTableData = [];
      this.$emit("setMarkerAtPoint", null);
    }

  },
  computed: {
    ...mapGetters({
      polygons: 'polygons',
      tempPolygons: 'tempPolygons',
    }), // array of {poly_id: string | number, 
        //           points: Array of {
        //               id: string | number,
        //               lat: number,
        //               lng: number           
        // }}
    hasActiveId () {
      return this.activeId != null;
    },
    validated () {
      return !this.$v.activeLat.$invalid && !this.$v.activeLng.$invalid;
    },
    polygon () {
      let result = [];
      if (this.editingMode == geoListViewMode.DRAWING) {
        result = this.tempPolygons.find(polygon => {
          return polygon.poly_id == this.activePolygonId;
        })
      } else {
        result = this.polygons.find(polygon => {
          return polygon.poly_id == this.activePolygonId;
        })
      }
      return result;
    },

    uploadOption () {
      return !this.activeId & this.unsavedChange;
    }
  },
  watch: {
    polygon(poly) {
      if (poly) {
        this.initializePointsData();
      } else {
        this.clearData();
      }
    },
  }
};
</script> 