var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    { staticStyle: { height: "300px" } },
    [
      _c(
        "CCardBody",
        [
          _c(
            "CContainer",
            [
              _vm._l(_vm.circularGeofencesFiltered, function(geo) {
                return _c(
                  "CBadge",
                  {
                    key: geo.getUId(),
                    staticStyle: { "margin-right": "10px" },
                    attrs: { color: _vm.badgeColor(geo.getUId()), size: "lg" },
                    on: {
                      click: function($event) {
                        _vm.setBadgeToActive(geo.getUId())
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(geo.getName()) + " ")]
                )
              }),
              _c("br"),
              _c("span", [
                _vm._v(
                  " Has matching result of " +
                    _vm._s(_vm.circularGeofencesFiltered.length) +
                    " "
                )
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }