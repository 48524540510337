<template>
  <div class="d-flex" style="background-color: white">
    <div class="filter">
      <CSelect
        horizontal
        :value.sync="activeBranch"
        :options="branches_options"
        placeholder="(No Branch Filter)"
      />
    </div>

    <div class="filter">
      <CSelect
        horizontal
        :value.sync="activeGeofenceTag"
        :options="geofenceTags_options"
        placeholder="(No Geofence Tag Filter)"
      />
    </div>

    <CButton @click="applyFilters" id="apply-btn" color="info">
      Apply
    </CButton>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  name: "FilterBar",
  components: {},
  props: {},
  data() {
    return {
      activeGeofenceTag: null,
      activeBranch: null
    };
  },
  methods: {
    applyFilters() {
      this.$emit("applyFilters", {
        geofenceTag: this.activeGeofenceTag,
        branch: this.activeBranch,
      });
    }
  },
  computed: {
    ...mapGetters({
      geofenceCategories: "geofenceCategories",
      branches: "branches",
    }),

    geofenceTags_options() {
      let output = this.geofenceCategories.map(geofenceTag => {
        return {
          value: geofenceTag.getUId(),
          label: geofenceTag.getName(),
        };
      })

      output.push({
        value: false,
        label: "(No Geofence Tag Filter)"
      })

      return output;
    },

    branches_options() {
      let output = this.branches.map(branch => {
        return {
          value: branch.id,
          label: branch.name,
        };
      })
      
      output.push({
        value: false,
        label: "(No Branch Filter)"
      })

      return output;
    }
  }
};
</script>

<style scoped>
.filter {
  width: 350px;
}

#apply-btn {
  margin: 10px;
}
</style>
