<template>
  <CCard style="height: 300px">
    <CCardBody>
      <CContainer>
        <CBadge 
          style="margin-right: 10px" 
          v-for="geo in circularGeofencesFiltered" 
          :key="geo.getUId()" 
          :color="badgeColor(geo.getUId())" 
          size="lg"
          @click="setBadgeToActive(geo.getUId())"
        >
          {{geo.getName()}}
        </CBadge>
        <br>
        <span> Has matching result of {{circularGeofencesFiltered.length}} </span>
      </CContainer>
    </CCardBody>
  </CCard>
</template>
<script>
import { mapGetters } from 'vuex'
import "vue-multiselect/dist/vue-multiselect.min.css";


export default {
  name: "GeofenceBadgeCard",
  props: {
    activeGeofenceUID: {
      default () {
        return null;
      }
    },
    filteredGeoUIDs: {
      default () {
        return null;
      }
    }
  },
  data() {
    return {
      sorterValue: { column: null, asc: false },
      selectedTags: [],
      selectedBranches: [],
      checkmarkEmoji: "\u2714\uFE0F",
    }
  },
  methods: {
    setBadgeToActive(uid) {
      // if (row.type == geofenceType.POLYGON) {
      //   this.$emit('setActivePolygon', row.id, null);
      // }
      this.$emit('setActiveCircular', uid);
    },

    badgeColor (uid) {
      return this.activeGeofenceUID == uid ? "success" : "info";
    },
  },
  computed: {
    ...mapGetters({
      polygonGroups: 'polygonGroups',
      circularGeofences: 'circularGeofences',
    }),

    circularGeofencesFiltered () {
      return this.circularGeofences.filter(geo => {
        return this.filteredGeoUIDs.some(uid => {
          return uid == geo.getUId();
        })
      })
    }
  },
};
</script> 