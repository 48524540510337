var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex", staticStyle: { "background-color": "white" } },
    [
      _c(
        "div",
        { staticClass: "filter" },
        [
          _c("CSelect", {
            attrs: {
              horizontal: "",
              value: _vm.activeBranch,
              options: _vm.branches_options,
              placeholder: "(No Branch Filter)"
            },
            on: {
              "update:value": function($event) {
                _vm.activeBranch = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter" },
        [
          _c("CSelect", {
            attrs: {
              horizontal: "",
              value: _vm.activeGeofenceTag,
              options: _vm.geofenceTags_options,
              placeholder: "(No Geofence Tag Filter)"
            },
            on: {
              "update:value": function($event) {
                _vm.activeGeofenceTag = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "CButton",
        {
          attrs: { id: "apply-btn", color: "info" },
          on: { click: _vm.applyFilters }
        },
        [_vm._v(" Apply ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }