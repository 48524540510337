<template>
    <CCard v-if="isCircular" class="m-0">
      <CCardHeader color="light">
        <div class="d-flex justify-content-between">
          <div ref="geoName" class="flash" >
            <b> {{geofence.getName()}} </b>
          </div>
          <div>
            <CButtonGroup>
              <CButton
                color="info"
                @click="openManualEditCircular(geofence.getUId())"
              >
              Edit
              </CButton>
              <CButton
                id="delete-btn"
                color="danger"
                @click="deleteBtnClicked"
              >
              Delete
              </CButton> 
              <CButton
                id="hide-detail-btn"
                color="primary"
                @click="hideDetailClicked"
              >
              Hide info
              </CButton>
              <!-- <CButton v-if="isPolygonGroup(geofence.type)"
                color="primary"
                shape="square"
                size="lg"
                @click="unlockPolygonGroup(geofence.id)"
              >  
              Unlock
              </CButton> -->
            </CButtonGroup>
          </div>
        </div>
      </CCardHeader>
      <CCardBody class="overflow-auto" style="padding: 20px">
        <div id="basic-info">
          <div><b>Description:</b> {{geofence.getDescription()}}</div>
          <div><b>Type: </b> Circular </div>
        </div>
        <div id="position-info">
          <span>
            <b>Coordinates:</b> {{geofence.getLatLngCasted()}}
          </span>
          <br>
          <span>
            <b>Radius: </b> {{geofence.getRadius()}} m
          </span>
        </div>
        <div id="other-info">
          <span> <b> Tags: </b> </span>
          <span v-for="(tag) in getTagObjects(geofence)" :key="tag.getUId()">
            <CBadge color="info" class="m-1 p-2 tagBadge" shape="pill"> 
              <span> {{tag.getName()}} </span>
            </CBadge>
          </span>
          <span v-if="!hasTags"> None </span>
        </div>
      </CCardBody>
      <CCardFooter color="secondary">
      </CCardFooter>
      <CModal
        :show.sync="showModal"
        centered
        fade
        color="secondary"
        border-color="secondary"
        size="xl"
      >
      <template #header-wrapper>
        <CCardHeader :color="$theme.backColor">
          <div class="default-card-header">
            <CIcon name="cil-bell"> </CIcon>
            Delete Geofence
          </div>
        </CCardHeader>
      </template>
      <template #body-wrapper>
        <div>Delete the geofence "{{ geofence.getName() }}" with uid: {{geofence.getUId()}} ? </div>
        <div class="d-flex flex-row-reverse">
          <CButton
            id="modal-cancel-btn"
            @click="closeModal(false)"
          >
          Cancel 
          </CButton>
          <CButton
            id="modal-confirm-btn"
            color="success"
            @click="closeModal(true)"
          >
          OK
          </CButton>
        </div>
      </template>
      <template #footer>
        <div :hidden="true"> </div>
      </template>
    </CModal>
    </CCard>
    <CCard v-else>
      Polygon Groups currently not supported in this version
    </CCard>
</template>


<script>
import { eventBus } from '@/main'
import { CircularGeofence, geofenceType, manualEditType } from '@/lib/geofencing_lib';
import { mapGetters } from 'vuex';
export default {
  name: "GeofenceDetailCard",
  props: {
    item: {
      type: Object,
    },    
  },
  data () {
    return {
      showModal: false,
    }
  },
  mounted() {
    eventBus.$on("triggerAFlash", () => {
      const el = this.$refs['geoName'];
      /* a hacky way to trigger reflow and replay animation
      /* https://stackoverflow.com/questions/6268508/restart-animation-in-css3-any-better-way-than-removing-the-element */
      el.style.animation = 'none';
      el.offsetHeight; 
      el.style.animation = null; 
    })
  },
  beforeDestroy: function() {
    eventBus.$off('triggerAFlash');
  },
  computed: {
    ...mapGetters({
      circularGeofences: "circularGeofences",
      polygonGroups: 'polygonGroups',
      geofenceCategories: 'geofenceCategories',
    }),

    geofence() {
      let result;
      if (this.item.type == geofenceType.CIRCULAR) {
        result = this.circularGeofences.find(geo => {
          return geo.getUId() == this.item.uid;
        })
      } else if (this.item.type == geofenceType.POLYGON) {
        result = this.polygonGroups.find(geo => {
          return geo.getId() == this.item.id;
        })
      } else {
        console.err('unsupported geofence type', this.item.type);
        result = null;
      }
      if (!result) {
        result = new CircularGeofence();
      }
      return result;
    },

    isCircular() {
      return this.item.type == geofenceType.CIRCULAR;
    },

    hasTags () {
      if (this.geofence) {
        return this.geofence.getCategories().length;
      } else {
        return false;
      }
    }
  },
  methods: {
    openManualEditCircular(uid) {
      this.$emit("openManualEdit", uid);
    },

    hideDetailClicked () {
      this.$emit("hideDetailClicked");
    },

    deleteBtnClicked () {
      this.showModal = true; 
    },

    closeModal (accept) {
      this.showModal = false;
      if (accept) {
        this.$emit("deleteAccepted")
      }
    },

    getTagObjects(geo) {
      return geo.getCategories().map(tag_uid => {
        return this.geofenceCategories.find(tag => {
          return tag.getUId() == tag_uid;
        })
      })
    },
  }
};
</script>

<style scoped>
.flash {
  animation: blinker 1s linear;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
