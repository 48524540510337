
<template>
  <div>

    <CRow color="primary" class="toolbar-container no-gutters  rounded"
    >
    <CCol col="7"  > 
      <FilterBar
        @applyFilters="applyFilters"
      ></FilterBar>
    </CCol>
    <CCol col="5">
      <CInput
      class="m-2 search-bar"
      lazy
      size="lg"
      
      placeholder="Search for a Geofence"

      :value.sync="searchValue"
      >
      </CInput>
    </CCol>
  </CRow>
    <CContainer fluid style="padding: 0 0 10px 0">
      <CRow class="no-gutters view-container">
        <CCol class="d-flex flex-column">
          <CRow class="flex-grow-1 map-container">
            <CCol class="">
              <LeafletMap
                ref="polygonMap"
                class="h-100"
                :center="polygonMapCenter"
                :activeRowMarker="activeRowMarker"
                :defaultZoom="14"
                @setActivePolygon="setActivePolygon"
                @setActiveCircular="setActiveCircular"
                :activeCircularUID="activeCircularUID"
                :activePolygonId="activePolygonId"
                :activeGroupId="activeGroupId"
                :unlockedGroupId="unlockedGroupId"
                :editingMode="mode"
                @showDetailClicked="showInfoCard"
                @switchMode="switchMode"
                @setUnlockedGroup="setUnlockedGroup"
                @addGeofenceClicked="goToAddGeofenceView"
                :filteredCircularGeofencesUIds="filteredCircularGeofencesUIds"
                :infoCard="infoCard"
              >
              </LeafletMap>
            </CCol>
          </CRow>
          <CRow>           
            <CCol >
              <div >
                <GeofenceDetailCard v-if="activeGeofence && infoCard"
                  :item="activeGeofence"
                  @openManualEdit="goToEditGeofenceView"
                  @hideDetailClicked="hideInfoCard"
                  @deleteAccepted="deleteActiveGeofence">
                </GeofenceDetailCard>
              </div>
            </CCol>
          </CRow>
        </CCol>
        <CCol class="table-container rounded">
          <div v-if="isInSelectMode" class="position-relative">
            <GeofenceBadgeCard v-if="isInBadgeView"
              :activeGeofenceUID="activeGeofenceId" 
              @setActivePolygon="setActivePolygon"
              @setActiveCircular="setActiveCircular"
              @unlockPolygonGroup="setUnlockedGroup"
              :filteredGeoUIDs="filteredCircularGeofencesUIds"
              
            >        
            </GeofenceBadgeCard>
            <GeofenceTableCard v-if="isInTableView"
              :filteredGeoUIDs="filteredCircularGeofencesUIds"
              @setActiveCircular="setActiveCircular"
              :activeGeofenceUID="activeGeofenceId" 
              class="h-100"
              :searchValue="searchValue"
              @detailsClick="goToDetailsView"
            >
            </GeofenceTableCard>
          </div>
        </CCol>
      </CRow>
    </CContainer>
    <CModal
      :show.sync="displayModal"
      :v-if="selectedGeofence"
      centered
      fade
      color="secondary"
      border-color="secondary"
      :title="selectedGeofence ? selectedGeofence.name : ''"
      size="xl"
    >
      <template #header-wrapper>
        <CCardHeader :color="$theme.backColor">
          <div class="default-card-header">
            <CIcon name="cil-bell"> </CIcon>
            Geofence View
          </div>
        </CCardHeader>
      </template>
      <template #body-wrapper>
        <GeofenceView
          v-if="selectedGeofence"
          :coordinates="selectedGeofence.coordinates.center"
          :address="selectedGeofence.description"
          :tags="selectedGeofence.tags"
          :type="selectedGeofence.type"
        />
      </template>
    </CModal>
  </div>
</template>


<script>
import { eventBus } from "@/main"
import GeofenceView from "./GeofenceView";
import LeafletMap from "@/components/dashboard/LeafletMap";
import BranchCard from "@/components/branches/BranchCard";
import CoordinateTable from '@/components/tables/CoordinateTable';
import GeofenceBadgeCard from '@/components/tables/GeofenceBadgeCard'
import CircularGeofenceTable from '@/components/tables/CircularGeofenceTable'
import GeofenceDetailCard from '@/components/geofence/GeofenceDetailCard' 
import FilterBar from "@/components/filters/FilterBar"
import GeofenceTableCard from '@/components/tables/GeofenceTableCard';
import { generateRandomId } from '@/lib/geofencing_lib';
import { manualEditType, geofenceType, geoListViewMode, ListMethod} from '@/lib/geofencing_lib'
import { mapGetters } from 'vuex';

const fields = [
  {
    key: "uid",
    name: "id",
    label: "ID",
    _style: "width:10%; min-width:10%; max-width:80px",
  },
  { key: "name", label: "Name", _style: "min-width:40%; font-weight: bold;" },
  { key: "description", label: "Description", _style: "min-width:20%;" },
  { key: "tags", label: "Tags", _style: "min-width:20%;" },
  /*{
    key: "type",
    label: "Type",
    _style: "min-width:1%",
  },*/
  {
    key: "show_details",
    label: "Details",
    _style: "min-width:1%",
  },
];

export default {
  name: "GeofenceListView",
  components: {
    GeofenceView,
    LeafletMap,
    BranchCard,
    CoordinateTable,
    GeofenceBadgeCard,
    GeofenceDetailCard,
    FilterBar,
    GeofenceTableCard,
  },
  data() {
    return {
      displayModal: false,
      _geofences: undefined,
      fields: fields,
      selectedGeofence: undefined,
      sorterValue: { column: null, asc: false },
      activePolygon: null,
      activeCircularUID: null,
      editNotUploaded: false,
      activelyEditing: false, 
      polygonMapCenter: [49.2, -122.8],
      polygonMapMarker: null,  //h_todo_0013_a1: perhaps set activeId on create to set the center
      activeRowMarker: null,
      unlockedGroupId: null,
      ADD_CIRCULAR: manualEditType.ADD_CIRCULAR,
      MODIFY_CIRCULAR: manualEditType.MODIFY_CIRCULAR,
      ADD_POLYGON: manualEditType.ADD_POLYGON,
      MODIFY_POLYGON: manualEditType.MODIFY_POLYGON,
      generateRandomId: generateRandomId,
      filters: {
        tags: [],
        branches: [],
      },
      listMethod: ListMethod.TABLE,
      searchValue: "",
      infoCard: true,
    };
  },
  computed: {
    ...mapGetters({
      geofenceCategories: "geofenceCategories",
      circularGeofences: "circularGeofences",
    }),

    geofenceData() {
      return this.$store.getters.geofences;
    },
    branch() {
      return this.$store.getters.selectedBranch;
    },
    polygonGroups() {
      return this.$store.getters.polygonGroups;
    },
    tempPolygons () {
      return this.$store.getters.tempPolygons;
    },

    // tempPolygonsFiltered () {
      // similar to circularGeofenceFiltered;
    // }

    circularGeofences () {
      return this.$store.getters.circularGeofences;
    },

    mapActiveEdit () {
      return this.tempPolygons && this.tempPolygons.getPolygons().length > 0;
    },

    mode () {
      if (this.unlockedGroupId) {
        return geoListViewMode.DRAWING;
      }
      return geoListViewMode.SELECT;
    },

    isInSelectMode () {
      return this.mode == geoListViewMode.SELECT;
    },

    isInDrawingMode () {
      return this.mode == geoListViewMode.DRAWING;
    },


    isInBadgeView () {
      return this.listMethod == ListMethod.BADGE;
    },

    isInTableView () {
      return this.listMethod == ListMethod.TABLE;
    },



    activePolygonId () {
      return this.activePolygon ? this.activePolygon.polyId : null;
    },

    activeGroupId () {
      return this.activePolygon ? this.activePolygon.groupId : null;
    },

    activeGeofenceId () {
      if (this.activePolygon) {
        return this.activePolygon.groupId;
      } else if (this.activeCircularUID) {
        return this.activeCircularUID;
      }
      return null;
    },

    activeGeofence () {
      if (this.activePolygon) {
        return {
          type: geofenceType.POLYGON,
          groupId: this.activePolygon.groupId,
          polyId: this.activePolygon.polyId,
        }
      } else if (this.activeCircularUID) {
        return {
          type: geofenceType.CIRCULAR,
          uid: this.activeCircularUID
        }
      }
      return null;
    },


    filteredCircularGeofencesUIds () {
      let result = this.circularGeofences;
      if (this.filters.geofenceTag) {
        result = this.circularGeofences.filter(geofence => {
          return geofence.hasCategory(this.filters.geofenceTag);
        })
      }
      
      if (this.filters.branch) {
        result = result.filter(geofence => {
          return geofence.branch == this.filters.branch;
        })
      }

      return result.map(geofence => {
        return geofence.getUId();
      })
    }

  },
  async mounted() {
    try {
      // await this.$app.loadGeofences();
      // await this.$app.loadPolygons();
    } catch (err) {
      console.log(err);
    }
  },

  methods: {
    getBadge(item) {},

    getTags(item) {
      try {
        let sp = item.tags.split(",");
        if (sp) sp = sp.map((item) => item.trim());
        return sp;
      } catch (err) {}
    },  
    getTagBadge(item) {
      return "primary";
    },
    getCoordinates(item) {},
    getCenter(item) {
      if (item.coordinates) {
        let center = item.coordinates.center;
        return [parseFloat(center.lat), parseFloat(center.lng)];
      }
    },
    getMarker(item) {
      if (item.coordinates) {
        let center = item.coordinates.center;
        return [parseFloat(center.lat), parseFloat(center.lng)];
      }
    },
    handleRowClicked(e) {},
    addGeofenceClicked(e) {},
    handleViewDetailsClick(item) {
      console.log(item);
      this.selectedGeofence = item;
      this.displayModal = true;
    },

    setActivePolygon(groupId, polyId) {
      this.activePolygon = {
        groupId,
        polyId,
      };
      this.activeCircularUID = null;
    },

    setActiveCircular(uid) {
      eventBus.$emit("triggerAFlash");
      this.activeCircularUID = uid;
      this.activePolygon = null;
    },

    setUnlockedGroup(id) {
      this.unlockedGroupId = id;
    },

    switchMode () {
      // Sep 22, 2022: DRAWING MODE DISABLE FOR NOW
      // if (this.mode == geoListViewMode.SELECT) {
      //   this.unlockedGroupId = generateRandomId();
      // } else if (this.mode == geoListViewMode.DRAWING) {
      //   this.unlockedGroupId = null;
      // }
    },

    setActiveRowMarker (latlng) {
      if (!latlng) {
        this.activeRowMarker = null;
        return;
      }
      this.polygonMapCenter = [latlng.lat, latlng.lng];
      this.activeRowMarker = [latlng.lat, latlng.lng];
    },


    showInfoCard () {
      this.infoCard = true;
    },

    hideInfoCard () {
      this.infoCard = false;
    },

    deleteActiveGeofence () {
      // h_test_MS01_01
      this.$app.deleteGeofence(this.activeCircularUID);
    },
    
    applyFilters (filters) {
      this.filters = filters;
    },

    goToAddGeofenceView () {
      this.$router.push({
        name: manualEditType.ADD_CIRCULAR, // h_ms_01
      });
    },

    goToEditGeofenceView (uid) {
      this.$router.push({
        name: manualEditType.MODIFY_CIRCULAR, // h_ms_01
        params: { 
          uid,
        },
      });
    },

    goToDetailsView (uid) {
      this.$router.push({
        name: "GeofenceDetails",
        params: {
          uid,
        }
      })
    }
  },

  watch: {
    activeGeofence () { // this watcher sets map focus to the active geofence
      if (!this.activeGeofence) {
        return;
      }
      if (this.activeGeofence.type == geofenceType.POLYGON) {
        const {groupId, polyId} = this.activePolygon;
        if (this.mode == geoListViewMode.SELECT) {
          const group = this.polygonGroups.find(group => {
            return group.getId() == groupId;
          })
          
          let polygon;
          if (polyId) { // if select from selectionTable, only groupId would be provided.
            polygon = group.getPolygons().find(polygon => {
              return polygon.getId() == polyId;
            })
          } else {
            polygon = group.getPolygons()[0]; // h_test: now we assume all the groups has at least one polygon
          }
          
          const point = polygon.getPointsCasted()[0];
          //h_todo_0010_01_a1: set to the center, instead of one of the points
          this.polygonMapCenter = point;
          this.polygonMapMarker = point;
        } else {
          const point = this.tempPolygons.findPolygon(polyId).getPointsCasted()[0];
          this.polygonMapCenter = point;
          this.polygonMapMarker = point;
        }
      } else if (this.activeGeofence.type == geofenceType.CIRCULAR) {
        const circular = this.circularGeofences.find(geo => {
          return geo.getUId() == this.activeCircularUID;
        })

        const center = circular.getLatLngCasted();
        this.polygonMapCenter = center;
        this.polygonMapMarker = center;
      } else {
        console.error("geofence type not supported", this.activegeofence.type);
      }
    },

    // when filter out the activeCircular, set activeCircularUID to null.
    filteredCircularGeofencesUIds () {
      if (!this.filteredCircularGeofencesUIds.includes(this.activeCircularUID)) {
        this.activeCircularUID = null;
      }
    },

    activeGeofenceId() {
      if (this.activeGeofenceId == null) {
        this.hideInfoCard();
      } 
    }
  },
};
</script>

<style scoped>
.toolbar-container{
  background-color: var(--white);
}

.search-bar{
  font-size: 18px;
}

.view-container{
  min-height: 400px;
}

.map-container{
  min-height: 400px;
}
.table-container{
  min-height: 400px;
  background-color: var(--white);
}
/*
@media screen and (max-width: 1150px) { 
  #sidebar {
    display: none;
  }

  .force-show-detail #sidebar{
    display: block;
  }
}

.force-hide-detail #sidebar{
  display: none;
}

.row-item {
  height: 40vh;
}

.expand-view .row-item {
  height: 70vh;
}

.expand-view.wrapped #polygonMapDiv {
  height: 40vh;
}  */
</style>
