var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        {
          staticClass: "toolbar-container no-gutters  rounded",
          attrs: { color: "primary" }
        },
        [
          _c(
            "CCol",
            { attrs: { col: "7" } },
            [_c("FilterBar", { on: { applyFilters: _vm.applyFilters } })],
            1
          ),
          _c(
            "CCol",
            { attrs: { col: "5" } },
            [
              _c("CInput", {
                staticClass: "m-2 search-bar",
                attrs: {
                  lazy: "",
                  size: "lg",
                  placeholder: "Search for a Geofence",
                  value: _vm.searchValue
                },
                on: {
                  "update:value": function($event) {
                    _vm.searchValue = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CContainer",
        { staticStyle: { padding: "0 0 10px 0" }, attrs: { fluid: "" } },
        [
          _c(
            "CRow",
            { staticClass: "no-gutters view-container" },
            [
              _c(
                "CCol",
                { staticClass: "d-flex flex-column" },
                [
                  _c(
                    "CRow",
                    { staticClass: "flex-grow-1 map-container" },
                    [
                      _c(
                        "CCol",
                        {},
                        [
                          _c("LeafletMap", {
                            ref: "polygonMap",
                            staticClass: "h-100",
                            attrs: {
                              center: _vm.polygonMapCenter,
                              activeRowMarker: _vm.activeRowMarker,
                              defaultZoom: 14,
                              activeCircularUID: _vm.activeCircularUID,
                              activePolygonId: _vm.activePolygonId,
                              activeGroupId: _vm.activeGroupId,
                              unlockedGroupId: _vm.unlockedGroupId,
                              editingMode: _vm.mode,
                              filteredCircularGeofencesUIds:
                                _vm.filteredCircularGeofencesUIds,
                              infoCard: _vm.infoCard
                            },
                            on: {
                              setActivePolygon: _vm.setActivePolygon,
                              setActiveCircular: _vm.setActiveCircular,
                              showDetailClicked: _vm.showInfoCard,
                              switchMode: _vm.switchMode,
                              setUnlockedGroup: _vm.setUnlockedGroup,
                              addGeofenceClicked: _vm.goToAddGeofenceView
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    [
                      _c("CCol", [
                        _c(
                          "div",
                          [
                            _vm.activeGeofence && _vm.infoCard
                              ? _c("GeofenceDetailCard", {
                                  attrs: { item: _vm.activeGeofence },
                                  on: {
                                    openManualEdit: _vm.goToEditGeofenceView,
                                    hideDetailClicked: _vm.hideInfoCard,
                                    deleteAccepted: _vm.deleteActiveGeofence
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("CCol", { staticClass: "table-container rounded" }, [
                _vm.isInSelectMode
                  ? _c(
                      "div",
                      { staticClass: "position-relative" },
                      [
                        _vm.isInBadgeView
                          ? _c("GeofenceBadgeCard", {
                              attrs: {
                                activeGeofenceUID: _vm.activeGeofenceId,
                                filteredGeoUIDs:
                                  _vm.filteredCircularGeofencesUIds
                              },
                              on: {
                                setActivePolygon: _vm.setActivePolygon,
                                setActiveCircular: _vm.setActiveCircular,
                                unlockPolygonGroup: _vm.setUnlockedGroup
                              }
                            })
                          : _vm._e(),
                        _vm.isInTableView
                          ? _c("GeofenceTableCard", {
                              staticClass: "h-100",
                              attrs: {
                                filteredGeoUIDs:
                                  _vm.filteredCircularGeofencesUIds,
                                activeGeofenceUID: _vm.activeGeofenceId,
                                searchValue: _vm.searchValue
                              },
                              on: {
                                setActiveCircular: _vm.setActiveCircular,
                                detailsClick: _vm.goToDetailsView
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("CModal", {
        attrs: {
          show: _vm.displayModal,
          "v-if": _vm.selectedGeofence,
          centered: "",
          fade: "",
          color: "secondary",
          "border-color": "secondary",
          title: _vm.selectedGeofence ? _vm.selectedGeofence.name : "",
          size: "xl"
        },
        on: {
          "update:show": function($event) {
            _vm.displayModal = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "header-wrapper",
            fn: function() {
              return [
                _c("CCardHeader", { attrs: { color: _vm.$theme.backColor } }, [
                  _c(
                    "div",
                    { staticClass: "default-card-header" },
                    [
                      _c("CIcon", { attrs: { name: "cil-bell" } }),
                      _vm._v(" Geofence View ")
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "body-wrapper",
            fn: function() {
              return [
                _vm.selectedGeofence
                  ? _c("GeofenceView", {
                      attrs: {
                        coordinates: _vm.selectedGeofence.coordinates.center,
                        address: _vm.selectedGeofence.description,
                        tags: _vm.selectedGeofence.tags,
                        type: _vm.selectedGeofence.type
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }