var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCircular
    ? _c(
        "CCard",
        { staticClass: "m-0" },
        [
          _c("CCardHeader", { attrs: { color: "light" } }, [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c("div", { ref: "geoName", staticClass: "flash" }, [
                _c("b", [_vm._v(" " + _vm._s(_vm.geofence.getName()) + " ")])
              ]),
              _c(
                "div",
                [
                  _c(
                    "CButtonGroup",
                    [
                      _c(
                        "CButton",
                        {
                          attrs: { color: "info" },
                          on: {
                            click: function($event) {
                              _vm.openManualEditCircular(_vm.geofence.getUId())
                            }
                          }
                        },
                        [_vm._v(" Edit ")]
                      ),
                      _c(
                        "CButton",
                        {
                          attrs: { id: "delete-btn", color: "danger" },
                          on: { click: _vm.deleteBtnClicked }
                        },
                        [_vm._v(" Delete ")]
                      ),
                      _c(
                        "CButton",
                        {
                          attrs: { id: "hide-detail-btn", color: "primary" },
                          on: { click: _vm.hideDetailClicked }
                        },
                        [_vm._v(" Hide info ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c(
            "CCardBody",
            { staticClass: "overflow-auto", staticStyle: { padding: "20px" } },
            [
              _c("div", { attrs: { id: "basic-info" } }, [
                _c("div", [
                  _c("b", [_vm._v("Description:")]),
                  _vm._v(" " + _vm._s(_vm.geofence.getDescription()))
                ]),
                _c("div", [_c("b", [_vm._v("Type: ")]), _vm._v(" Circular ")])
              ]),
              _c("div", { attrs: { id: "position-info" } }, [
                _c("span", [
                  _c("b", [_vm._v("Coordinates:")]),
                  _vm._v(" " + _vm._s(_vm.geofence.getLatLngCasted()) + " ")
                ]),
                _c("br"),
                _c("span", [
                  _c("b", [_vm._v("Radius: ")]),
                  _vm._v(" " + _vm._s(_vm.geofence.getRadius()) + " m ")
                ])
              ]),
              _c(
                "div",
                { attrs: { id: "other-info" } },
                [
                  _c("span", [_c("b", [_vm._v(" Tags: ")])]),
                  _vm._l(_vm.getTagObjects(_vm.geofence), function(tag) {
                    return _c(
                      "span",
                      { key: tag.getUId() },
                      [
                        _c(
                          "CBadge",
                          {
                            staticClass: "m-1 p-2 tagBadge",
                            attrs: { color: "info", shape: "pill" }
                          },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(tag.getName()) + " ")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  !_vm.hasTags ? _c("span", [_vm._v(" None ")]) : _vm._e()
                ],
                2
              )
            ]
          ),
          _c("CCardFooter", { attrs: { color: "secondary" } }),
          _c("CModal", {
            attrs: {
              show: _vm.showModal,
              centered: "",
              fade: "",
              color: "secondary",
              "border-color": "secondary",
              size: "xl"
            },
            on: {
              "update:show": function($event) {
                _vm.showModal = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header-wrapper",
                  fn: function() {
                    return [
                      _c(
                        "CCardHeader",
                        { attrs: { color: _vm.$theme.backColor } },
                        [
                          _c(
                            "div",
                            { staticClass: "default-card-header" },
                            [
                              _c("CIcon", { attrs: { name: "cil-bell" } }),
                              _vm._v(" Delete Geofence ")
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "body-wrapper",
                  fn: function() {
                    return [
                      _c("div", [
                        _vm._v(
                          'Delete the geofence "' +
                            _vm._s(_vm.geofence.getName()) +
                            '" with uid: ' +
                            _vm._s(_vm.geofence.getUId()) +
                            " ? "
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-row-reverse" },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: { id: "modal-cancel-btn" },
                              on: {
                                click: function($event) {
                                  return _vm.closeModal(false)
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "CButton",
                            {
                              attrs: {
                                id: "modal-confirm-btn",
                                color: "success"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.closeModal(true)
                                }
                              }
                            },
                            [_vm._v(" OK ")]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "footer",
                  fn: function() {
                    return [_c("div", { attrs: { hidden: true } })]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              618442519
            )
          })
        ],
        1
      )
    : _c("CCard", [
        _vm._v(" Polygon Groups currently not supported in this version ")
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }