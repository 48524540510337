var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCardBody",
    [
      _c("CDataTable", {
        attrs: {
          items: _vm.CTableData,
          fields: _vm.fields,
          "items-per-page": 20,
          hover: "",
          pagination: "",
          clickableRows: "",
          cleaner: ""
        },
        on: { "row-clicked": _vm.setRowToFocus },
        scopedSlots: _vm._u([
          {
            key: "lat",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _vm.activeId == item.id
                      ? _c("CInput", {
                          attrs: {
                            isValid: !_vm.$v.activeLat.$invalid,
                            invalidFeedback:
                              "has to be a number between -90 and 90, with length under 25"
                          },
                          on: { blur: _vm.showV },
                          model: {
                            value: _vm.activeLat,
                            callback: function($$v) {
                              _vm.activeLat = $$v
                            },
                            expression: "activeLat"
                          }
                        })
                      : _c("strong", [_vm._v(" " + _vm._s(item.lat) + " ")])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "lng",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _vm.activeId == item.id
                      ? _c("CInput", {
                          attrs: {
                            isValid: !_vm.$v.activeLng.$invalid,
                            invalidFeedback:
                              "enter a number between -180 and 180, with length under 25"
                          },
                          on: { blur: _vm.showV },
                          model: {
                            value: _vm.activeLng,
                            callback: function($$v) {
                              _vm.activeLng = $$v
                            },
                            expression: "activeLng"
                          }
                        })
                      : _c("strong", [_vm._v(" " + _vm._s(item.lng) + " ")])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "editing",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.hasActiveId,
                        expression: "!hasActiveId"
                      }
                    ],
                    attrs: { color: "primary", shape: "square", size: "lg" },
                    on: {
                      click: function($event) {
                        return _vm.editToggleOn(item.id)
                      }
                    }
                  },
                  [_vm._v(" edit ")]
                ),
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeId == item.id && _vm.validated,
                        expression: "activeId == item.id && validated"
                      }
                    ],
                    attrs: { color: "success", shape: "square", size: "lg" },
                    on: {
                      click: function($event) {
                        return _vm.saveEdit(item.id)
                      }
                    }
                  },
                  [_vm._v(" save ")]
                ),
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeId == item.id,
                        expression: "activeId == item.id"
                      }
                    ],
                    attrs: { color: "warning", shape: "square", size: "lg" },
                    on: {
                      click: function($event) {
                        return _vm.editToggleOff(false)
                      }
                    }
                  },
                  [_vm._v(" cancel ")]
                ),
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.hasActiveId,
                        expression: "!hasActiveId"
                      }
                    ],
                    attrs: { color: "success", shape: "square", size: "lg" },
                    on: {
                      click: function($event) {
                        return _vm.addCoordinates(item.id)
                      }
                    }
                  },
                  [_vm._v(" add ")]
                )
              ]
            }
          }
        ])
      }),
      _vm.polygon && _vm.uploadOption
        ? _c(
            "CButton",
            {
              attrs: { color: "success", shape: "square", size: "lg" },
              on: {
                click: function($event) {
                  return _vm.uploadPolygonToStore()
                }
              }
            },
            [_vm._v(" upload edit ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }