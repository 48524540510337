var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    { staticClass: "m-0" },
    [
      _c(
        "CCardBody",
        [
          _c("CDataTable", {
            staticStyle: { "font-size": "14px" },
            attrs: {
              items: _vm.circularGeofencesFiltered,
              fields: _vm.fields,
              "items-per-page": 6,
              hover: "",
              pagination: "",
              cleaner: false,
              "clickable-rows": "",
              striped: true,
              "table-filter": false,
              tableFilterValue: _vm.searchValue,
              sorter: "",
              "sorter-value": _vm.sorterValue
            },
            on: { "row-clicked": _vm.setRowToActive },
            scopedSlots: _vm._u([
              {
                key: "tags",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      [
                        _vm._l(_vm.getTagObjects(item), function(tag, index) {
                          return _c(
                            "span",
                            { key: tag.getUId() },
                            [
                              index < 3
                                ? _c(
                                    "CBadge",
                                    {
                                      staticClass: "m-1 p-2 tagBadge",
                                      attrs: { color: "info", shape: "pill" }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " + _vm._s(tag.getName()) + " "
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        _vm.getTagObjects(item).length > 3
                          ? _c("span", [_vm._v(" ... ")])
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                }
              },
              {
                key: "name",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("td", [
                      _c("b", [_vm._v(" " + _vm._s(item.getName()) + " ")])
                    ])
                  ]
                }
              },
              {
                key: "branch",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("td", [_vm._v(" " + _vm._s(item.getBranch()) + " ")])
                  ]
                }
              },
              {
                key: "show",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      [
                        _c(
                          "CButton",
                          {
                            attrs: { color: "info", variant: "ghost" },
                            on: {
                              click: function($event) {
                                return _vm.detailsClick(item)
                              }
                            }
                          },
                          [_vm._v(" Details ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }